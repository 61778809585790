import React, { useEffect } from 'react';


const Pub = () => {

    useEffect(() => {
        console.log("test")
        try {
            console.log("try")
            const adsbygoogle = window.adsbygoogle || []
            adsbygoogle.push({})
            console.log(adsbygoogle)
        } catch (e) {
            console.error(e)
        }
    }, [])

    return (

        <div className="page_pub">

            <div className="container_pub"
                style={{
                    display: "block",
                    height: "90vh",
                    width: "100%"
                }}>



                <ins className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client="ca-pub-9287539611941501"
                    data-ad-slot="6105105399"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
            </div>



            <h1>Je teste</h1>
        </div>

    );
};

export default Pub;

/*

<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9287539611941501"
                    crossOrigin="anonymous"></script>
                <ins className="adsbygoogle"
                    style={{display:'block'}}
                    data-ad-client="ca-pub-9287539611941501"
                    data-ad-slot="6105105399"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({ });
                </script>



*/